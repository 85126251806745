import React from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import BackButton from '../components/backButton'
import * as styles from './templates.module.css'
import Callout from '../components/callout'
import { getTextCopy } from '../helpers'

const JobPosting = ({ data }) => {
  const { seoTitle, slug, title, jobPostingContent, excerpt } = data.jobPosting
  const { seoImage, pageSlug, content, callout } = data.jobPostingPage
  const { careersPageSlug } = data.careersPage
  const { openPositionsHash } = data.openPositionsHashContent.value

  const fullSlug = pageSlug.replace(`{{slug}}`, slug)

  return (
    <>
      <SEO
        title={seoTitle}
        description={excerpt}
        imageUrl={seoImage.file.url}
        urlPath={fullSlug}
      />
      <Layout callout={callout && <Callout data={callout} slug={slug} />}>
        <div className={styles.pageWrapper}>
          <div className={styles.pageHeader}>
            <Link to={`${careersPageSlug}#${openPositionsHash}`}>
              <BackButton>
                {getTextCopy(content, 'page.jobPosting.backButton')}
              </BackButton>
            </Link>
            <h1 className={styles.postTitle}>{title}</h1>
            <p className={styles.snippet}>{excerpt}</p>
          </div>
          <div
            className={styles.html}
            style={{ marginTop: '50px' }}
            dangerouslySetInnerHTML={{
              __html: jobPostingContent.childMarkdownRemark.html,
            }}
          />
        </div>
      </Layout>
    </>
  )
}

export default JobPosting

export const jobPostingQuery = graphql`
  query jobPostingQuery($slug: String!) {
    jobPosting: contentfulJobPosting(slug: { eq: $slug }) {
      seoTitle
      slug
      title
      excerpt
      jobPostingContent: content {
        childMarkdownRemark {
          html
        }
      }
    }
    jobPostingPage: contentfulPage(name: { eq: "page.jobPosting" }) {
      ...pageFields
    }
    careersPage: contentfulPage(name: { eq: "page.careers" }) {
      careersPageSlug: slug
    }
    openPositionsHashContent: contentfulContent(
      key: { eq: "page.careers.openPositionsHash" }
    ) {
      value {
        ... on ContentfulMicrocopy {
          openPositionsHash: value
        }
      }
    }
  }
`
