// extracted by mini-css-extract-plugin
export var articleHeader = "templates-module--articleHeader--2a419";
export var articleHeaderWrapper = "templates-module--articleHeaderWrapper--4e312";
export var articleMeta = "templates-module--articleMeta--65f5e";
export var articleMetaWrapper = "templates-module--articleMetaWrapper--e87e2";
export var articleTags = "templates-module--articleTags--3b2c0";
export var articleWrapper = "templates-module--articleWrapper--ba18e";
export var authorAvatar = "templates-module--authorAvatar--243c8";
export var authorBio = "templates-module--authorBio--cfd2c";
export var authorInfo = "templates-module--authorInfo--ea860";
export var authorName = "templates-module--authorName--ea7f0";
export var authorTitle = "templates-module--authorTitle--0a756";
export var authorWrapper = "templates-module--authorWrapper--8b5bb";
export var backButtonContainer = "templates-module--backButtonContainer--43366";
export var bullet = "templates-module--bullet--ed450";
export var content = "templates-module--content--13263";
export var crossPostDetails = "templates-module--crossPostDetails--9b0d9";
export var crossPostWrapper = "templates-module--crossPostWrapper--b9213";
export var excerpt = "templates-module--excerpt--08e22";
export var html = "templates-module--html--cb7b7";
export var image = "templates-module--image--25d39";
export var pageHeader = "templates-module--pageHeader--02bc3";
export var pageWrapper = "templates-module--pageWrapper--6ce86";
export var postTitle = "templates-module--postTitle--036b4";
export var readTime = "templates-module--readTime--bfa56";
export var readTimeContainer = "templates-module--readTimeContainer--61794";
export var relatedArticleCard = "templates-module--relatedArticleCard--d5985";
export var relatedArticlesContainer = "templates-module--relatedArticlesContainer--a13fa";
export var relatedArticlesTitle = "templates-module--relatedArticlesTitle--5ddc7";
export var relatedArticlesWrapper = "templates-module--relatedArticlesWrapper--34e43";
export var relatedService = "templates-module--relatedService--a37e2";
export var relatedServiceExcerpt = "templates-module--relatedServiceExcerpt--82a7c";
export var relatedServiceTitle = "templates-module--relatedServiceTitle--3a327";
export var relatedServicesGrid = "templates-module--relatedServicesGrid--3b421";
export var relatedServicesWrapper = "templates-module--relatedServicesWrapper--b2e10";
export var serviceContent = "templates-module--serviceContent--91dc4";
export var serviceGridColumn = "templates-module--serviceGridColumn--6b3ad";
export var serviceHtml = "templates-module--serviceHtml--97129";
export var servicePageGrid = "templates-module--servicePageGrid--0bcc3";
export var servicePageWrapper = "templates-module--servicePageWrapper--18e7d";
export var serviceTitle = "templates-module--serviceTitle--2d0c0";
export var sidebar = "templates-module--sidebar--62014";
export var sidebarFlex = "templates-module--sidebarFlex--e490b";
export var snippet = "templates-module--snippet--81cb4";
export var thumbnail = "templates-module--thumbnail--6335d";
export var title = "templates-module--title--1ade4";